<!--
 * @Description: 应用管理
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:55:24
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 11:34:47
-->
<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus">新增</el-button>
  </el-card>
  <el-card class="box data">
    <el-table :data="data_list" v-loading="loading" border>
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="状态" width="140">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog
    v-model="visibleDialog"
    :title="title"
    width="600px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      saving: false,
      //数据
      data_list: [],
      visibleDialog: false,
      title: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.get("admin/v1/flowType").then((res) => {
        if (res.code == 0) {
          this.data_list = res.data;
        }
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.form = Object.assign({}, item);
      if (item) {
        this.title = "修改";
      } else {
        this.title = "新增";
      }
      this.visibleDialog = true;
    },

    /**
     *
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/flowType/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
                this.visibleDialog = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/flowType/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>
